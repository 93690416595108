import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Hero, Layout, Listing, Wrapper } from '../components'
import website from '../../config/website'

const Main = Wrapper.withComponent('main')

const ForOhFour = ({
  data: {
    pages: { totalCount, edges }
  }
}) => {
  return (
    <Layout>
      <Hero title='Pagina niet gevonden' invert />
      <Main id={website.skipNavId}>
        <p>Deze pagina bestaat helaas niet</p>

        {totalCount > 0 && (
          <Listing
            title={`${totalCount} bestaande ${totalCount === 1 ? 'pagina' : 'paginas'}`}
            date={edge => edge.node.date}
            edges={edges}
          />
        )}
      </Main>
    </Layout>
  )
}

ForOhFour.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            uid: PropTypes.string.isRequired,
            date: PropTypes.string,
            data: PropTypes.shape({
              title: PropTypes.shape({
                text: PropTypes.string.isRequired
              }).isRequired
            }).isRequired
          }).isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired
}

export default ForOhFour

export const pageQuery = graphql`
  query ForOhFourPage {
    pages: allPrismicPage {
      totalCount
      edges {
        node {
          uid
          date: first_publication_date(fromNow: true, locale: "nl")
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`
